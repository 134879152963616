import React, { useEffect, useState } from "react";

// import global styles
import GlobalStyle from "./components/styles/GlobalStyle";
import Grid from "./components/styles/Grid";

import Layout from "./components/Layout.js";
import { BrowserRouter as Router } from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";

import { LoginRoutes, AppRoutes } from "./routes";

import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";

window.msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  /*useEffect(async () => {
    const orgData = await loadOrganizationData();
    setData(orgData);
  }, []);*/


  return (
    <MsalProvider instance={window.msalInstance}>
      <Router>
        <GlobalStyle />
        <Grid />
        <AuthenticatedTemplate>
            <Layout>
              <AppRoutes />
            </Layout>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginRoutes />
        </UnauthenticatedTemplate>
      </Router>
    </MsalProvider>
  );
}
