import styled from "styled-components";
import React from "react";

const B = styled.button`
  border: none;
  background-color: transparent;
`;

export default function Followers() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.34542 0.710246C3.90369 0.665885 2.68377 1.79709 2.6394 3.26099C2.6394 3.28318 2.6394 3.30536 2.6394 3.32754C2.6394 4.76926 3.81497 5.92265 5.25669 5.92265C6.67624 5.94483 7.8518 4.83581 7.89616 3.41626C7.91835 1.95235 6.78714 0.754606 5.34542 0.710246ZM6.56534 3.3719C6.54316 4.05949 5.96647 4.614 5.27887 4.59182H5.25669H5.23451C4.90181 4.59182 4.59128 4.45874 4.3473 4.21475C4.10331 3.97077 3.97023 3.61588 3.97023 3.26099C3.97023 2.55122 4.54692 1.99671 5.23451 1.99671C5.25669 1.99671 5.25669 1.99671 5.27887 1.99671C5.63376 1.99671 5.94429 2.12979 6.18827 2.37378C6.45444 2.63994 6.58752 2.99483 6.56534 3.3719Z"
        fill="white"
      />
      <path
        d="M10.0256 9.13875C9.73721 8.76168 9.33796 8.47334 8.89435 8.31807C8.07368 7.98537 7.18646 7.76356 6.29924 7.65266C4.76879 7.47522 3.21616 7.69702 1.77443 8.27371C0.931578 8.60642 0.288346 9.13875 0.0665413 10.0481C0.0665413 10.0703 0 10.9354 0 11.3346V12H10.4248C10.4248 11.5786 10.4913 11.135 10.4913 10.7136C10.5135 10.1591 10.3361 9.60454 10.0256 9.13875ZM2.32894 10.2256C3.34924 9.22747 6.58759 9.07221 8.33984 10.2256H2.32894Z"
        fill="white"
      />
      <path
        d="M10.5579 0.000387991C9.38234 -0.0217924 8.42858 0.909786 8.4064 2.08535C8.38422 3.23873 9.29362 4.19249 10.447 4.23685C11.6226 4.28121 12.5985 3.37181 12.6429 2.21843C12.6429 2.19625 12.6429 2.19625 12.6429 2.17407C12.6429 0.976327 11.7113 0.0225684 10.5579 0.000387991ZM10.5135 2.88384H10.4914C10.2917 2.88384 10.0921 2.8173 9.95903 2.66204C9.80377 2.50678 9.73723 2.30715 9.73723 2.08535C9.73723 1.66392 10.0921 1.30903 10.5135 1.30903C10.5135 1.30903 10.5135 1.30903 10.5357 1.30903C10.7353 1.30903 10.935 1.37557 11.0902 1.53084C11.2455 1.70828 11.3342 1.93009 11.312 2.15189C11.2899 2.57332 10.935 2.88384 10.5135 2.88384Z"
        fill="white"
      />
      <path
        d="M14.3507 6.83199C14.1067 6.52147 13.7962 6.29966 13.4413 6.16658C12.7759 5.90041 12.0661 5.72297 11.3564 5.63425C10.2695 5.50117 9.18268 5.63425 8.1402 5.96696C7.98494 6.01132 7.85186 6.07786 7.6966 6.12222C7.23081 6.27748 6.83156 6.58801 6.54321 7.00944C7.20863 7.09816 7.85186 7.23124 8.49509 7.45304C8.71689 7.51959 8.91652 7.60831 9.13832 7.69703H9.1605C9.71501 7.91883 10.1808 8.27372 10.5357 8.71733C10.6244 8.85041 10.7131 8.98349 10.7797 9.11658H14.6834C14.6834 8.76169 14.7278 8.42898 14.7278 8.0741C14.75 7.63049 14.6169 7.18688 14.3507 6.83199ZM12.9977 7.71921H11.4229C11.2233 7.47523 10.9793 7.2756 10.7353 7.07598L10.9571 7.09816H11.0458L11.2898 7.12034L11.4673 7.14252C11.6891 7.18688 11.9109 7.23124 12.1105 7.29778C12.177 7.31996 12.2436 7.34214 12.2879 7.36432C12.3545 7.3865 12.4654 7.43086 12.5541 7.47522L12.7094 7.54177C12.8203 7.60831 12.909 7.65267 12.9977 7.71921Z"
        fill="white"
      />
    </svg>
  );
}
