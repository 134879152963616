import axios from "axios";

export const getToken = async () => {
  const instance = window.msalInstance;
  const account = window.msalInstance.getAllAccounts()[0];

  const accessTokenRequest = {
    scopes: process.env.REACT_APP_ADB2C_SCOPES.split(","),
    account: account,
  };

  return instance
    .acquireTokenSilent(accessTokenRequest)
    .then((accessTokenResponse) => {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
      // Call your API with token
      return accessToken;
    })
    .catch((error) => {
      console.log("acquireTokenSilent error", error);
      instance.logout();
      //alert(error);
    });
};

export const getAxiosClient = async () => {
  const token = await getToken();
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: { common: { Authorization: `Bearer ${token}` } },
  });

  return axiosClient;
};
