import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import PageHeaderSub from "../components/PageHeaderSub";
import Page from "../components/UI/Elements/Page";
import Button from "components/UI/Buttons/Button";
import { getAxiosClient } from "api/axios";
import Loader from "components/UI/Loader";

const Registration = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const { code } = useParams();
  const { accounts } = useMsal();

  const account = useAccount(accounts?.[0] || {});
  const { given_name, family_name } = account?.idTokenClaims;

  const registerWithCode = async () => {
    const axiosClient = await getAxiosClient();
    try {
      return await axiosClient
        .get(`/loader/invite/${code}/investor`)
        .then(async (response) => {
          console.log(response.data);
          return await axiosClient
            .post(`/loader/invite/${code}/register`)
            .then((response) => {
              console.log(response.data);
              setLoading(false);
              return response.data;
            })
            .catch((error) => {
              console.log("error", error);
              setLoading(false);
              return Promise.reject(error);
              //alert("error", error);
            });
        })
        .catch((error) => {
          setLoading(false);
          if (error?.repsonse?.data?.errorCode === "invite-already-completed") console.log("error", error);
          else return Promise.reject(error);
          //alert("error", error);
        });
    } catch (error) {
      setLoading(false);
      console.log({ ...error });
      if (error?.response?.data?.errorCode === "invite-already-completed") setErrorMessage("Invite already Completed. Contact support for more info.");
      else
        error?.response?.data?.errorCode
          ? setErrorMessage(error?.response?.data?.errorCode + ". Contact support for more info.")
          : setErrorMessage("Something went wrong. Contact support for more info.");
    }
  };

  useEffect(async () => {
    await registerWithCode();
  }, []);

  return (
    <Page>
      <div className="page__header">
        <h1>{`Welcome ${given_name} ${family_name}`}</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        {loading ? (
          <>
            <br></br>
            Completing your registration...
            <Loader />
          </>
        ) : errorMessage ? (
          <>
            <br></br>
            {errorMessage}
            <br></br>
            <br></br>
            You can download the app for <a target='_blank' href="https://apps.apple.com/be/app/finvictum/id6444575602">IOS</a> or <a target='_blank' href="https://play.google.com/store/apps/details?id=com.finvictum">Android</a> here.
          </>
        ) : (
          <>
            <br></br>
            <h1>Thank you for registering on Finvictum.</h1>
            <br></br>
            Download the app for <a target="_blank" href="https://apps.apple.com/be/app/finvictum/id6444575602">IOS</a> or <a target="blank" href="https://play.google.com/store/apps/details?id=com.finvictum">Android</a> to login.
          </>
        )}
      </div>
    </Page>
  );
};

export default Registration;
