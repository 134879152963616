import React, { useEffect } from "react";
import styled from "styled-components";

import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";

import Header from "./Header";
import Footer from "./Footer";
import Navigation from "./Navigation";
import LoaderContainer from "./UI/Loader";
import { getOrganization } from "api/services/organization";

const Main = styled.main`
  margin: 0 auto;
  padding: 8rem 0;
  max-width: calc(100% - 32rem);
  background-color: var(--color-background-prim);
`;

const LoadOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background-prim);
`;

const Layout = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts?.[0] || {});


  return (
    <>
      <Header />
      <div className="row">
        <Navigation />
        {false && (
          <LoadOverlay>
            <LoaderContainer />
          </LoadOverlay>
        )}
        <Main className="col">{children}</Main>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
