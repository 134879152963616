import styled from "styled-components";
import React from "react";

export default function Logout() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M2.5709 1.71968C2.61505 2.62636 2.6265 3.34726 2.10971 4.10151C0.704875 6.16575 1.1955 8.86516 3.14002 10.4864C4.06602 11.268 5.25049 11.7004 6.47746 11.7047C7.70442 11.7091 8.89213 11.2852 9.82401 10.5102C11.7473 8.94931 12.287 6.25309 10.9574 4.20313C10.4914 3.50591 10.3057 2.66665 10.4357 1.84512C12.0433 2.54062 13.2731 5.31941 12.9477 7.53133C12.742 8.90386 12.0827 10.1744 11.0689 11.1515C10.0551 12.1287 8.74179 12.7596 7.32671 12.9492C5.90796 13.1174 4.47151 12.8298 3.23701 12.1304C2.0025 11.431 1.03788 10.3583 0.490634 9.07634C-0.613278 6.46586 0.173362 3.5521 2.5709 1.71968Z"
          fill="#F2F2F3"
        />
        <path
          d="M7.13378 2.23892V6.63894H5.88759V2.30878H4.05591L6.55157 0L8.81663 2.23892H7.13378Z"
          fill="#F2F2F3"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
