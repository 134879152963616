import React from "react";
import {Switch, Route } from "react-router-dom";
import Login from "../pages/login.js";
import Registration from "../pages/registration";

export const LoginRoutes = () => {
  return (
    <Switch>
      <Route path="/">
        <Login />
      </Route>
    </Switch>
  );
};

export const AppRoutes = () => {  
  return (
    <Switch>
      <Route path="/registration/:code">
        <Registration />
      </Route>
    </Switch>
  );
};
