import { createGlobalStyle } from "styled-components";
import reset from "reset-css";

export default createGlobalStyle`
  ${reset}

html {
    --color-background-prim: #2f2f2f;
    --color-background-sec: #5f5f5f;
    --color-accent-prim: #FF8972;
    --color-accent-sec: #AA6355;
    --color-text: #f2f2f2;
    --color-black: #1A1A1A;
    --color-light-grey: #D5D5D5;
    --color-medium-grey: #3A3A3A;
    --color-dark-grey: #2A2A2A;
    --color-status-new: #D5D5D5;
    --color-status-good: #31de96;
    --color-status-bad: #b32d15;
    font-size: 62.5%;
    }

:root {
    position: relative;
    min-height: 100vh;
    width:100vw;
    margin:auto;
    font-size: 62.5%;
    color: var(--color-text);
    font-family: 'Nunito', sans-serif;}
html, *{box-sizing: border-box;}
*,*:before,*:after {box-sizing: inherit;}
::-webkit-scrollbar {
    /*display: none;*/
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
body{
    background-color: white;
    color: var(--color-text);
    font-family: 'Nunito', sans-serif;
    text-align: left;
    font-size: 1.6rem;
    overflow-x: hidden;
}

/*-------main_style-------*/

a{
  text-transform: capitalize;
  text-decoration: underline;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  color: white;
  }

img {
  width: 100%;
  height: auto;
}

button{
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  border-radius:100px;
  font-size: 2.4rem;
  border:var(--color-accent-prim) solid 1px;
  background-color: var(--color-background-sec);
  color: var(--color-accent-prim);
  -webkit-box-shadow: var(--color-accent-prim);
  box-shadow: var(--color-accent-prim);
  cursor: pointer;
  outline: none;
;}

table {
  color: var(--color-light-grey);
  font-family: 'Nunito', sans-serif;
  th{
    border-bottom: 1px solid var(--color-light-grey);
    opacity: 1;
    position: relative;
  }
  tr {
    vertical-align: middle;
    &:last-of-type{
      td{
        border-bottom: none;
      }
    }
  }
  td {
    opacity: .8;
    padding: 1rem 0;
    &:not(:first-of-type){
      border-bottom: 1px solid var(--color-light-grey);
    }
  }
  &.table{
    td{
      border-bottom: 1px solid var(--color-light-grey);
    }
  }
}

.label {
  color: var(--color-light-grey);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
}

input[type="file"] {
  cursor: pointer;
}

svg{
  cursor: pointer;
}

/*-------Headers-------*/

h1 {
  font-weight: 500;
  font-size: 3.6rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

h2 {
  font-weight: 500;
  font-size: 1.8rem;
  font-family: 'Montserrat', sans-serif;
}

h3 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

h4 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

h5 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

h6 {
  font-weight: 500;
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
}

/*-------Form_elements-------*/
label{

}

input, textarea{
  border:none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    &:focus {
      outline: none;
    }  
}

input{
  padding: .4rem;
  color: var(--color-text);
  border-bottom: 1px solid var(--color-light-grey);
  font-size: 1.8rem;
}

input:focus {
    outline: none !important;
    border-bottom: 1px solid var(--color-accent-prim);
    box-shadow: 0 5px 10px -7px var(--color-accent-prim);
  }

textarea{
  border-radius: .8rem;
  background-color:var(--color-text);
  padding: .8rem;
}

textarea:focus {
    outline: none !important;
    border: 1px solid var(--color-accent-prim);
    box-shadow: 0 5px 10px -7px var(--color-accent-prim);
  }

select{
  width: 100%;
  padding: .4rem
}

.required:after {
    content:" *";
    color: var(--color-accent-prim);
  }

/*-------Nav_style-------*/
nav ul{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
nav a{ 
  font-size: 1.6rem;
  text-decoration: none;
  color: #F2F2F2;
  width: 100%;
  padding-left: 8rem;
  margin-top: 1.8rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  & svg{
    margin-right: .8rem
  }
}
nav a
:hover{
  color: var(--color-accent-prim);
  opacity: .7;
  & svg{
      path{
        fill: var(--color-accent-prim);
      }
    }
}
&.active{
    color: var(--color-accent-prim);
    & svg{
      path{
        fill: var(--color-accent-prim);
      }
    }
  }
nav ul {
  flex-direction:column;
  text-align:left;
}

/*------------------------*/
.card{
  border-radius: 0.8rem;
  background-color: white;
  color: var(--color-dark-grey);
  height: 100%;
  display: flex;
  flex-direction: column;  
    
  :hover{
    opacity: .7;
   }
    &__title{
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1.8rem;
    }
    &__subtitle {
      color: var(--color-dark-grey);
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1.2rem;
      svg{
        margin-left: .2rem;
      }
      path{
        fill: var(--color-dark-grey);
      }
    }
  }
  .card_image__container {
    position: relative;
    width: 100%;
    padding-top: 52.6%;
    overflow: hidden;
  }
  img {
    border-radius: 0.8rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: auto;
  }
  .edit-button {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
    cursor: pointer;
  }
  .delete-button {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    cursor: pointer;
  }
  .card__content {
    padding: 1.2rem 0.8rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__body {
      margin: 1.2rem 0;
      font-weight: 300;
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1.4rem;
      color: var(--color-accent-prim);
      font-family: "Montserrat";
    }
  }

  .switch-container{
    display: flex;
  }
  .switch-item {
    margin: 1.4rem;
    &-column{
      flex-direction: column;
    }
  }
  .modal-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
    }
  }
  /*------------------------*/

  .cover-image {   
    height: 20rem;
    width: 100%;
    position: relative;
    border-radius: 0.8rem;  
  }

  .page__header__sub > * {
    margin-left: 1rem;
  }

  .grid-add-btn {
    display: flex;
    align-items: center;
    margin: .8rem 0;
  }
  
  .thumbnail{
    padding: 6rem;
  }

  .form{
    label {
      display: block;
      margin-bottom: 0.4rem;
    }
    input {
      width: 100%;
    }
    textarea {
      width: 100%;
      height: 10rem;
    }
    .form_row {
      margin-bottom: 2rem;
      display: flex;
      width: 100%;
      .field {
        width: 100%;
        &:not(:first-of-type) {
          margin-left: 2.4rem;
        }
      }
    }
    .error-message {
      opacity: 0.6;
      color: red;
      padding: 1rem 0;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-right: 1rem;
      }
    }
  }
`;
